a {
  color: inherit;
  text-decoration: none;
}

figure div img {
  width: 100% !important;
}

.hideLogo {
  opacity: 0;
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

.showLogo {
  opacity: 1;
}

.hideTitle {
  opacity: 0;
}

.showTitle {
  opacity: 1;
  -webkit-animation: fadein 1s;
  animation: fadein 1s;
}

/* Toastify */

.Toastify__toast--info {
  background: #2080ff !important;
}

.Toastify__toast--error {
  background: #ff2050 !important;
}

.Toastify__toast--warning {
  background: #ffa040 !important;
}

.Toastify__toast--success {
  background: #20c820 !important;
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}